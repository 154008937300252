import React from "react"

const ExpandedFeature = (props: ExpandedFeatureProps) => {
    return (
        <div className="bg-light-gradient flex flex-row justify-center items-center text-neutral-900">
            <div className="flex flex-col justify-between w-1/2 ">
                <h2 className="text-3xl m-8 font-bold text-center">{props.name}</h2>
                <p className="text-xl pl-12 pr-12 mb-8 text-left px-36">{props.description}</p>
            </div>
            <div className="w-1/4 ml-8 py-4">
                {props.children}
            </div>


        </div>
    )
}

type ExpandedFeatureProps = {
    name: string,
    description: string,
    children?: React.ReactNode,
}

export default ExpandedFeature;