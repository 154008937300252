import { StaticImage } from "gatsby-plugin-image"
import React, { useState } from "react"
import CompressedFeatures from "./CompressedFeatures"
import ExpandedFeature from "./ExpandedFeature"
import MarketingImage from '../images/marketing-cropped.svg'
import WebDesignImage from '../images/web-design-cropped.svg'
import WebAppImage from '../images/web-app-cropped.svg'
import FullStackIntegrationImage from '../images/full-stack-integration-cropped.svg'

const descriptions = {
    "Marketing / SEO": <>
        <ExpandedFeature name="Marketing / SEO" description="
    While marketing strategies such as advertisments and social media presence 
    can be very effective, they are not the only way to increase your website's visibility. 
    SEO (Search Engine Optimization) is the process of fine tuning your website's code and 
    design in order to increase it's ranking against your competitors, making it appear
     higher in search results. This technique is essential for increasing impressions and 
     sales making it a fantastic long term investment.
     ">
            <MarketingImage />
        </ExpandedFeature>
    </>,
    "Web Design": <>
        <ExpandedFeature name="Web Design" description="
     A great website is essential for any business looking to maximise their reach and 
     increase sales. Our superb web designers won't only build you a great looking bespoke 
     website, but will also ensure it has amazing performance and will last years. Unlike other 
     companies that use decades-old technologies to build websites that need updating every few 
     years, we use the latest and greatest tech to ensure your website will run flawlessly for
      years to come.
        ">
            <WebDesignImage />
        </ExpandedFeature>
    </>,
    "Web Applications": <>
        <ExpandedFeature name="Web Applications" description="
    Many great ideas end up producing mediocre results simply due to a poor implementation. 
    These disapointing implementations are usually the result of larger companies cutting 
    corners during their development process in order to speed through projects. Our team 
    however, is well versed in a plethora of cutting-edge technologies allowing them to 
    build bespoke web-apps with fine attention to detail and outstanding performance.
    "
        >
            <WebAppImage />
        </ExpandedFeature>
    </>,
    "Full-Stack Integration": <>
        <ExpandedFeature name="Full-Stack Integration" description="
    Already have a front or back-end system? Our team of full-stack developers can fill 
    the gap and create a highly integrated solution that will perfectly complement your
     existing systems.
     ">
            <FullStackIntegrationImage />
        </ExpandedFeature>
    </>,
} as { [key: string]: React.ReactNode }

const FeatureSection = () => {

    const [desc, setDesc] = useState<React.ReactNode | null>(null);

    const handleClick = (key: string) => {
        let newDesc = descriptions[key];
        if (desc === newDesc) {
            setDesc(null);
        } else {
            setDesc(descriptions[key]);
        }
    }

    return (
        <>
            <CompressedFeatures handleClick={handleClick} />
            <div className="bg-dark-gradient">
                {desc}
            </div>
        </>
    )
}

export default FeatureSection;