import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import Feature from './Feature';
import MarketingImage from '../images/marketing-cropped.svg'
import WebDesignImage from '../images/web-design-cropped.svg'
import WebAppImage from '../images/web-app-cropped.svg'
import FullStackIntegrationImage from '../images/full-stack-integration-cropped.svg'
import { motion, useScroll } from 'framer-motion';
import useMediaQuery from '../hooks/useMediaQuery';

const CompressedFeatures = (props: compressedFeatureProps) => {
    const isMobile = useMediaQuery("(max-width: 768px)");
    return (
        <>
            <motion.div variants={isMobile ? mobileVariants : compVariants} initial="hidden" whileInView="visible" viewport={{ once: true, }}>
                <div className="bg-transparent grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 container mx-auto justify-evenly content-center lg:gap-5 pb-8">
                    <Feature name="Marketing / SEO" description="Increase impressions, drive conversions." handleClick={props.handleClick}>
                        <MarketingImage />
                    </Feature>
                    <Feature name="Web Design" description="Modern, mobile-friendly, perfomant. " handleClick={props.handleClick}>
                        <WebDesignImage />
                    </Feature>
                    <Feature name="Web Applications" description={<p>Advanced security, highly <br /> optimised.</p>} handleClick={props.handleClick}>
                        <WebAppImage />
                    </Feature>
                    <Feature name="Full-Stack Integration" description="Project completion, seemless unification." handleClick={props.handleClick}>
                        <FullStackIntegrationImage />
                    </Feature>
                </div>
            </motion.div>
        </>
    )
}

export default CompressedFeatures;

type compressedFeatureProps = {
    handleClick: (key: string) => void,
}

const compVariants = {
    hidden: {
        opacity: 0,
        y: 100,
        scale: 0.8,
    },
    visible: {
        opacity: 1,
        y: 0,
        scale: 1,
        transition: {
            delay: 0.25,
            duration: 0.5,
            ease: "easeInOut",
        },
    },
}

const mobileVariants = {
    hidden: {
        opacity: 0,
        y: 100,
        scale: 0.8,
    },
    visible: {
        opacity: 1,
        y: 0,
        scale: 1,
        transition: {
            delay: 0,
            duration: 0.5,
            ease: "easeInOut",
        },
    },
}