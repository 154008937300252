import React from 'react';
import SectionTitle from './SectionTitle';
import { motion } from 'framer-motion';

const PricingSection = () => {
    return (
        <>
        <section id="pricing" className="bg-light-gradient">
            <SectionTitle>Pricing</SectionTitle>
            <div className="flex justify-center items-center">
                <motion.p variants={variants} initial="hidden" whileInView="visible" viewport={{ once: true, }} className="text-neutral-900 text-xl mx-8 md:mx-32 lg:mx-46 xl:mx-64 mb-16">
                Due to the nature of our bespoke services, our pricing model
                 is highly dependent on the type of service we are providing. 
                 This considered, we aim to provide a far superior value for
                  money than any of our competitors including besting any quotes
                   you may receive from our competitors.
                </motion.p>
            </div>
            </section>
        
        </>
    );
}

export default PricingSection;

const variants = {
    hidden: {
        opacity: 0,
        y: -20
    },
    visible: {
        opacity: 1,
        y: 0,
        transition: {
            delay: 0.5,
            duration: 0.5
        }
    }
}

