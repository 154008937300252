import * as React from 'react';

const Footer = () => {
    return (
        <footer className="text-center text-neutral-600 pt-8 pb-5">
            <p>
                Code Bakery © {new Date().getFullYear()}
            </p>
        </footer>
    );
}

export default Footer;