import * as React from 'react';
import NavBar from './NavBar';
import ParticlesContainer from './ParticlesContainer';

const HomeSection = () => {

  return (
    <section id="home" className="bg-transparent relative h-85vh">

      <NavBar />

      <div className="flex flex-col justify-center container px-8 mx-auto py-64">
        <h1 className="text-5xl md:text-5xl lg:text-7xl font-bold text-left my-3 z-2 font-poppins">
          Bespoke <span className="bg-gradient-to-br from-teal-400 to-emerald-500 bg-clip-text text-transparent">Web</span> Solutions.
        </h1>

        <h3 className="text-left z-2 text-[16px] md:text-lg lg:text-2xl font-bold text-white/80">
        The one-stop shop for web development.
        </h3>
      </div>

      <ParticlesContainer />

    </section>
  )
}

export default HomeSection;
