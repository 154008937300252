import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import SectionTitle from "../components/SectionTitle"
import CompressedFeatures from './CompressedFeatures';
import FeatureSection from './FeatureSection';

const positions = {
    left: 'flex-start',
    right: 'flex-end',
    center: 'center',
}

const AboutSection = (props: AboutSectionProps) => {
    return (
        <section id="about" className="bg-light-gradient text-neutral-900 h-fit">
            <SectionTitle>How we can help.</SectionTitle>
            <FeatureSection />
        </section>
    );
}

type AboutSectionProps = {
    children?: React.ReactNode
    className?: string
    // title?: string
    // image: string
    // alt?: string
    // description?: string
    // position?: string

}

export default AboutSection;