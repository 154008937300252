import * as React from 'react';
import SectionTitle from './SectionTitle';
import { BsYoutube, BsTwitter, BsInstagram } from 'react-icons/bs';

const ContactSection = () => {
    return (
        <section id="contact" className="">
            <SectionTitle>Contact</SectionTitle>
            <div className="flex flex-row flex-wrap justify-evenly items-center ">

                <span className="text-4xl md:text-5xl lg:text-6xl text-teal-400 hover:text-emerald-500">
                    <a href="https://youtube.com/codebakery">
                        <BsYoutube />
                    </a>
                </span>
                <div>
                    <span className="text-4xl md:text-5xl lg:text-6xl text-teal-400 hover:text-emerald-500">
                        <a href="https://twitter.com/code_bakery">
                            <BsTwitter  />
                        </a>
                    </span>
                </div>
                <div>
                    <span className="text-4xl md:text-5xl lg:text-6xl text-teal-400 hover:text-emerald-500">
                        <a href="https://instagram.com/thecodebakery">
                            <BsInstagram />
                        </a>
                    </span>
                </div>




            </div>
        </section>
    );
}

export default ContactSection;