import * as React from 'react';
import { Link } from 'gatsby';

const NavBar = () => {
    return (
        <nav className="backdrop-blur bg-[rgba(0,0,0,0.01)] shadow-md">
            <div className="container mx-auto px-6 py-4">
                <div className="flex justify-between items-center">
                    <Link to="">
                        <h3 className="text-2xl md:text-3xl font-bold font-poppins hover:text-neutral-300">
                            Code Bakery
                        </h3>
                    </Link>
                    <div className="flex items-center text-neutral-100">
                        <Link to="/#about" className="mr-6  hover:text-neutral-300">
                            About
                        </Link>
                        <Link to="/#contact" className="hover:text-neutral-300">
                            Contact
                        </Link>
                    </div>
                </div>
            </div>
        </nav>
    );
}

export default NavBar;