import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { animate, motion } from 'framer-motion';

const Features = (props: FeatureProps) => {

    const onClick = () => {
        props.handleClick(props.name);
    }

    return (

        <div className={"bg-transparent flex flex-col justify-between " + props.className}>
            <div className="flex content-center justify-center h-48 m-2 ">
                {props.children || <StaticImage className="h-32 w-32 m-6" src="../images/icon.png" alt="Place holder image" />}
            </div>
            <h3 className="text-2xl text-center font-bold">{props.name}</h3>
            <p className="text-center m-6">{props.description}</p>
            <div className="flex justify-center">
                <motion.button whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }} onClick={onClick} className="bg-transparent hidden lg:block hover:bg-teal-500 text-teal-500 font-semibold hover:text-white w-64 md:w-72 py-2 px-4 mx-8 border border-blue
            hover:border-transparent rounded">Learn More</motion.button>
            </div>


        </div>
    )
}

export default Features;

type FeatureProps = {
    name: string,
    description: React.ReactNode,
    handleClick: (key: string) => void,
    children?: React.ReactNode,
    className?: string,
}

