import * as React from 'react';

const SectionTitle = (props: SectionTitleProps) => {
    return (
        <div className="bg-transparent">

            <div className="flex justify-center items-center">
                <h2 className="font-poppins font-bold text-4xl sm:text-6xl m-5 sm:m-10 h-[43px] sm:h-[69px] bg-gradient-to-br from-teal-400 to-emerald-500 bg-clip-text text-transparent">
                    {props.children}
                </h2>
            </div>
        </div>
    );
}

type SectionTitleProps = {
    children?: React.ReactNode
}

export default SectionTitle;