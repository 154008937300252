import React, { useCallback } from "react";
import Particles from "react-particles";
import type { Engine, IOptions, RecursivePartial } from "tsparticles-engine";
import { loadFull } from "tsparticles";
import useMediaQuery from "../hooks/useMediaQuery";

const ParticlesContainer = () => {

  const isMobile = useMediaQuery("(max-width: 768px)");

  // this customizes the component tsParticles installation
  const customInit = useCallback(async (engine: Engine) => {
    // this adds the bundle to tsParticles
    await loadFull(engine);
  }, []);

  const options: RecursivePartial<IOptions> = {
    fpsLimit: 60,
    particles: {
      color: {
        value: "rgba(50,140,200)",
      },
      links: {
        enable: true,
        color: "rgba(30,140,200)",
        distance: 150
      },
      move: {
        enable: true,

      },
      number: {
        density: {
          enable: true,
          value_area: 1000
        }
      },

    },
    pauseOnBlur: true,
    retinDetect: true,
    fullScreen: {
      enable: false,

    },
    backgroundMask: {
      enable: false,

    },
  };

  const interactiveOptions: RecursivePartial<IOptions> = {
    fpsLimit: 60,
    interactivity: {
      detect_on: "window",
      events: {
        onhover: {
          enable: true,
          mode: "repulse"
        }
      }
    },
    ...options
  };



  return <Particles options={isMobile ? options : interactiveOptions} init={customInit} className="absolute top-0 left-0 h-full w-full -z-10 bg-dark-gradient" />;
}

export default ParticlesContainer;