import React from "react";
import SectionTitle from "./SectionTitle";
import { motion, LayoutGroup, animate } from 'framer-motion';

const WhyUsSection = () => {
    return (
        <section id="whyus" className="bg-dark-gradient text-neutral-50 h-fit">
            <SectionTitle>Why choose us?</SectionTitle>
            <div className="flex flex-col items-center justify-center h-fit">
                <div className="grid grid-cols-1 md:grid-cols-2 md:grid-rows-2 justify-evenly content-center container gap-y-12 px-8 md:gap-x-6 lg:gap-y-24 lg:gap-x-12 lg:px-36 mb-16">
                    <motion.h3 variants={variants} initial="hidden" whileInView="visible" viewport={{ once: true, }} className="text-xl lg:text-2xl">Latest technologies and frameworks providing you with a competitive edge.</motion.h3>
                    <motion.h3 variants={variants2} initial="hidden" whileInView="visible" viewport={{ once: true, }} className="text-xl lg:text-2xl">Full-Stack development providing a more comprehensive solution than competitors.</motion.h3>
                    <motion.h3 variants={variants3} initial="hidden" whileInView="visible" viewport={{ once: true, }} className="text-xl lg:text-2xl">Small team providing unmatched attention to detail and customer care.</motion.h3>
                    <motion.h3 variants={variants4} initial="hidden" whileInView="visible" viewport={{ once: true, }} className="text-xl lg:text-2xl">Unbeatable price to quality.</motion.h3>
                </div>
            </div>

        </section>
    );
}

export default WhyUsSection;

const variants = {
    hidden: {
        opacity: 0,
        y: -20
    },
    visible: {
        opacity: 1,
        y: 0,
        transition: {
            delay: 0.5,
            duration: 0.5
        }
    }
}

const variants2 = {
    hidden: {
        opacity: 0,
        y: -20
    },
    visible: {
        opacity: 1,
        y: 0,
        transition: {
            delay: 1,
            duration: 0.5
        }
    }
}

const variants3 = {
    hidden: {
        opacity: 0,
        y: -20
    },
    visible: {
        opacity: 1,
        y: 0,
        transition: {
            delay: 1.5,
            duration: 0.5
        }
    }
}

const variants4 = {
    hidden: {
        opacity: 0,
        y: -20
    },
    visible: {
        opacity: 1,
        y: 0,
        transition: {
            delay: 2,
            duration: 0.5
        }
    }
}


















