import * as React from "react"
import type { HeadFC } from "gatsby"
import AboutSection from "../components/AboutSection"
import Footer from "../components/Footer"
import HomeSection from "../components/HomeSection"
import ContactSection from "../components/ContactSection"
import WhyUsSection from "../components/WhyUsSection"
import PricingSection from "../components/PricingSection"




const IndexPage = () => {
  return (
    <div className="bg-transparent relative text-neutral-100">
      <HomeSection />
      <AboutSection />
      <WhyUsSection />
      <PricingSection />
      <ContactSection />
      <Footer />
    </div>

  )
}

export default IndexPage

export const Head: HeadFC = () => <title>Code Bakery</title>
